import React from "react";
import Router from "./Router";
function Layout() {
 
  return (
    <>
        <Router />
    </>
  );
}
export default Layout;
